import { getLocalizationById } from '@context/useMyRouting/helpers';
import useBasket from '@pages/Basket/useBasket';
import { formatPrice } from '@pages/OfferDetail/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SummaryItemStyledContainer from './StyledComponents';

/**
 * Пропсы компонента
 */
type SummaryItemProps = {
  value: number;
  code?: string;
};

/**
 * Компонент вывода итоговой стоимости товара
 * @param props
 * @constructor
 */
const SummaryItem = (props: SummaryItemProps) => {
  const { value, code } = props;
  const [order] = useBasket().useOrder().useState();
  const langCode = getLocalizationById(order.language_id).countryCode;
  const { t } = useTranslation('OfferDetail', { lng: langCode });

  return (
    <SummaryItemStyledContainer>
      <span>{t('OfferDetail:OfferSummaryCostPlank')}:</span>
      <span className={'value'}>
        {formatPrice(value)} {code}
      </span>
    </SummaryItemStyledContainer>
  );
};

export default SummaryItem;
