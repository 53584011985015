import RoutePathString from '@components/pages/RoutePathString';
import { getLocalizationById } from '@context/useMyRouting/helpers';
import Typography from '@mui/material/Typography';
import { formatPrice } from '@pages/AdditionalServices/tabs/DeliveryForm/components/RoutesListComponent/helpers';
import { Order } from '@services/requests/orderService/interface';
import {
  CalculatedPrice,
  ProductSummaryProps,
  RouteResult,
} from '@services/requests/orderService/types';
import { BranchItem } from '@services/requests/routeCalculator/locationsBranchSearchService/interfaces';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import RouteSummaryStyledContainer from './StyledComponents';

/**
 * Пропсы компонента
 */
type RouteSummaryProps = {
  order: Order;
  cargo: string[];
} & ProductSummaryProps &
  CalculatedPrice;

/**
 * Компонент вывода обобщенной информации по маршруту
 */
const RouteSummary = (props: RouteSummaryProps) => {
  const {
    product: { data },
    cargo,
    order,
    orderCurrency,
    fullPriceInTargetCurrency,
    taxInTargetCurrency,
  } = props;

  const langCode = getLocalizationById(order.language_id).countryCode;

  const { t } = useTranslation(['Common', 'OfferDetail'], { lng: langCode });
  // Получаем данные маршрута
  const route: RouteResult = JSON.parse(data);

  const {
    terminals,
    routePointsItems: { from, to },
  } = route;

  let points: BranchItem[] = [
    ...(route.route.routes[0].route.steps
      .map((s) => terminals.find((term) => term?.id === s.startTerminal?.id))
      .filter((s) => !!s) as BranchItem[]),
    to,
  ];

  // Массив 'terminals' может не содержать начального терминала
  if (points[0]?.id !== from?.id && from.type === 'terminal') {
    points = [from, ...points];
  }

  // если отправка идет не из терминала
  if (from.type !== 'terminal') {
    points.unshift(from);
  }

  const dateCalculation = dayjs(order?.date || new Date())
    .locale('ru')
    .format('D.MM.YY');

  const rubleCurrency = route.baseData.currencies.find((c) => c.id === '1');
  const currentCurrency = route.baseData.currencies.find((c) => c.id === orderCurrency);

  const currentCurrencyCourse =
    route.route.routes[0].route.steps[0].shoulderOfferPriceCondition.currency?.course;
  const orderCurrencyList = route.baseData.currencies
    .filter((c) => c?.id !== rubleCurrency?.id)
    .map((c) => (
      <Typography key={c.code} variant={'subtitle1'} component={'div'}>
        <span>
          {c.nominal} {c.code} = {c.course || currentCurrencyCourse} {rubleCurrency?.code || 'RUB'}
        </span>
      </Typography>
    ));

  const paramsName = route.type === 'container' ? t('Common:Containers') : false;
  return (
    <>
      <RouteSummaryStyledContainer>
        <div className="body">
          <Typography variant={'subtitle1'} component={'div'} className="route">
            <RoutePathString value={points} langCode={langCode} order={order} />
          </Typography>
          <ul className="cargo-params">
            {paramsName ? <span>{paramsName}: </span> : ''}
            {cargo.map((c, i) => (
              <li className={'cargo-params__item'} key={`cargo-params--${i}`}>
                <Typography
                  variant={'subtitle1'}
                  component={'span'}
                  className="cargo-params"
                  key={`cargo-params--${i}`}
                >
                  {c}
                </Typography>
              </li>
            ))}
          </ul>
          <Typography variant={'subtitle1'} component={'div'} className="delivery-time">
            {t('OfferDetail:NetTransitTime')}
            <sup>1</sup>: {route.route.deliveryTime}{' '}
            {route.route.deliveryTime > 1 ? t('Common:Days') : t('Common:Day')}
          </Typography>
        </div>
        <div className="aside">
          <Typography variant={'subtitle1'} component={'div'} className="full-cost">
            {t('Common:Total')}: {formatPrice(fullPriceInTargetCurrency)} {currentCurrency?.code}
          </Typography>
          <Typography variant={'subtitle1'} component={'div'} className="tax">
            {t('OfferDetail:IncludingVAT')}: {formatPrice(taxInTargetCurrency)}{' '}
            {currentCurrency?.code}
          </Typography>
          {!!orderCurrencyList.length && (
            <div className="currency">
              <Typography variant={'subtitle1'} component={'div'}>
                {t('OfferDetail:ExchangeRateOn')} {dateCalculation}:
              </Typography>
              {orderCurrencyList}
            </div>
          )}
        </div>
      </RouteSummaryStyledContainer>
    </>
  );
};

export default RouteSummary;
